import React, { useContext, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router';

import { fetchSupportApi, post } from '../../api';
import { AuthTokenContext, UserContext, ConfigContext } from '../../context';
import uploadAttachments from '../uploadAttachments';
import CustomPopup from '../../components/utils/CustomPopup';
import FilesDisplay from '../../components/file-upload/FilesDisplay/FilesDisplay';
import styles from './NewIssue.module.scss';
import MessageStyles from '../../components/message/Message.module.scss';

import Select from 'react-select';

import DesktopLayout  from '../../components/utils/DesktopLayout';
import MobileLayout  from '../../components/utils/MobileLayout';
import ReactQuill from 'react-quill';
import { modules, formats } from '../../components/utils/QuillEditorConfig';
import '../../components/utils/QuillEditor.css';
import YoutubeEmbed from '../../utils/YoutubeEmbed';

const isBlankMessage = (message) => message.toString('html') === '<p><br></p>';

const acceptsMimeTypes = [
  'audio/*',
  'image/*',
  'video/*',
  'text/*',
  'application/pdf',
  'application/x-freearc',
  'application/x-bzip',
  'application/x-bzip2',
  'application/gzip',
  'application/x-rar-compressed',
  'application/x-tar',
  'application/zip',
  'application/x-7z-compressed',
].join(',');

const createIssue = async (
  subject,
  priority,
  description,
  product,
  category,
  note,
  stepsToReproduce,
  user,
  stakeHolder,
  clientPriority,
  userRole,
  reason,
  requirementType,
  expectedBehaviour,
  requestType,
) => {
  const resp = await post('/issue', {
    subject,
    priority,
    description,
    product,
    category,
    note,
    stepsToReproduce,
    stakeHolder,
    clientPriority,
    userRole,
    reason,
    requirementType,
    expectedBehaviour,
    requestType,
    email_from: `${user.name} <${user.email}>`,
  });

  if (resp.ok) {
    const { issue_id } = await resp.json();
    return issue_id;
  }
  else {
    let _resp = await resp.json();
    return _resp;
  }
};

const NewIssue = ({ history }) => {
  const { user } = useContext(UserContext);
  const { config, refreshConfig } = useContext(ConfigContext);
  const { isTokenValid } = useContext(AuthTokenContext);

  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);

  const [subject, setSubject] = useState('');
  const [priority, setPriority] = useState('0');
  const [categoryApi, setCategoryApi] = useState([]);
  const [productApi, setProductApi] = useState([]);
  const [product, setProducts] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [stepsToReproduce, setStepsToReproduce] = useState('');
  const [requestType, setRequestType] = useState('Bug/Support');
  const [stakeHolder, setStakeHolder] = useState('');
  const [clientPriority, setClientPriority] = useState('Low');
  const [userRole, setUserRole] = useState('');
  const [reason, setReason] = useState('');
  const [requirementType, setRequirementType] = useState('Business Requirement');
  const [expectedBehaviour, setExpectedBehaviour] = useState('');
  const [descriptionErr, setDescriptionErr] = useState('');
  const [stepsToReproduceErr, setStepsToReproduceErr] = useState('');
  const [ticketSubjectErr, setTicketSubjectErr] = useState('');
  const [errors, setErrors] = useState();
  const [visibility, setVisibility] = useState(true);
  const [buttonState, setButtonState] = useState(false);
  const [default_product, setDefaultProduct] = useState([]);
  const [default_category, setDefaultCategory] = useState([]);

  const Roles = [
    { value: 'practitioner', label: 'Practitioner' },
    { value: 'practice manager', label: 'Practice manager' },
    { value: 'receptionist', label: 'Receptionist' },
    { value: 'billing expert', label: 'Billing expert' },
    { value: 'cashier', label: 'Cashier' },
    { value: 'credit controller', label: 'Credit controller' },
    { value: 'stock controller', label: 'Stock controller' },
    { value: 'bookkeeper', label: 'Bookkeeper' },
    { value: 'other', label: 'Other' },
  ];

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  const severityDesc = {
    "0": "Administrative type requests e.g. Creating of users",
    "1": "Any requests for new functions or enhancements on GoodX",
    "2": "Small error or bug",
    "3": "Sporadic system errors/incidents that has small impact on the operability of the system",
    "4": "Errors/incidents impacting the operability of the system to a lesser extent",
    "5": "GoodX Systems completely unavailable or available but completely inoperable",
  };

  const onRemoveFile = (index) => {
    setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index));
  };

  const validateFields = async () => {
    if (subject === "") {
      setTicketSubjectErr("Please enter a description to describe the subject.");
      setVisibility(true);
    }
    else if (subject !== "" && subject.length < config.len_ticket_subject){
        setTicketSubjectErr(`Please enter at least ${config.len_ticket_subject} characters to describe the subject`);
        setVisibility(true);
    }
    else {
      setTicketSubjectErr('');
    }
    if (description === "") {
      setDescriptionErr("Please enter a description to describe the issue.");
      setVisibility(true);
    }
    else if (description !== "" && description.length < config.len_ticket_description){
        setDescriptionErr(`Please enter at least ${config.len_ticket_description} characters to describe the issue`);
        setVisibility(true);
    }
    else {
      setDescriptionErr('');
    }
    if (stepsToReproduce === "") {
      setStepsToReproduceErr("Please enter steps to reproduce the issue.");
      setVisibility(true);

    }
    else if (stepsToReproduce !== "" && stepsToReproduce.length < config.len_ticket_steps_to_rep){
        setStepsToReproduceErr(`Please enter at least ${config.len_ticket_steps_to_rep} characters to describe the steps to reproduce`);
        setVisibility(true);
    }
    else {
      setStepsToReproduceErr('');
    }
    if (description !== "" && stepsToReproduce !== "" && subject !== ""
    && description.length >= config.len_ticket_description
    &&  stepsToReproduce.length >= config.len_ticket_steps_to_rep
    && subject.length >= config.len_ticket_subject) {
      await newIssueSubmit(files);
    }
  };

 const charLen = (fieldName,value, config) => {
  switch (fieldName) {
    case 'ticket_subject':
        if (value.length < config.len_ticket_subject){
            setTicketSubjectErr(`Please enter at least ${config.len_ticket_subject} characters to describe the subject`);
            }
        else{
            setTicketSubjectErr("");
            };
        break
    case 'issue_description':
        if (value.length < config.len_ticket_description){
            setDescriptionErr(`Please enter at least ${config.len_ticket_description} characters to describe the issue`);
            }
        else{
            setDescriptionErr("");
            };
        break
    case 'steps_to_reproduce':
        if (value.length < config.len_ticket_steps_to_rep){
            setStepsToReproduceErr(`Please enter at least ${config.len_ticket_steps_to_rep} characters to describe the steps to reproduce`);
            }
        else{
            setStepsToReproduceErr("");
            };
        break
  }
};

  const newIssueSubmit = async (files) => {
    setButtonState(true);
    const issueID = await createIssue(
      subject,
      priority,
      description,
      product !== "" ? parseInt(product):"",
      category !== "" ? parseInt(category):"",
      message.toString('html'),
      stepsToReproduce,
      user,
      stakeHolder,
      clientPriority,
      userRole,
      reason,
      requirementType,
      expectedBehaviour,
      requestType,
    );
    if(issueID.error) {
      let errArr = issueID.fields;
      let jsxElems = errArr.map((e,i) => <li key={i}>{e}</li>);
      setErrors(jsxElems);
      setVisibility(true);
      setButtonState(false);
    } else if (issueID !== undefined) {
      if (!files || (await uploadAttachments(issueID, subject, files))) {
        history.push({ pathname: `/issue/${issueID}` });
        return true;
      }
    }
    setButtonState(false);
    return false;
  };

  useEffect(() => {
    if (isTokenValid()) {
      fetchSupportApi(`/support/categories`, setCategoryApi);
      fetchSupportApi(`/support/products`, setProductApi);
      fetchSupportApi(`/support/default_product`, setDefaultProduct);
      fetchSupportApi(`/support/default_category`, setDefaultCategory);
    }
  }, [isTokenValid]);

  useEffect(() => {
      default_product.map((product) => (
        setProducts(product.default_cp_product)
      ))
      default_category.map((category) => (
        setCategory(category.default_cp_category)
      ))
  },[default_product,default_category]);

   useEffect(() => {
        refreshConfig();
    },[]);

  return (
    isTokenValid() ? (

      <div className={styles.FormWrapper}>

        <div className={styles.FormHeader}>
          <div className={styles.Heading}>
            <h1>Log a New Ticket</h1>
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <div className={styles.FieldLabel}>
            Type of Request <span>*</span>
          </div>
          <div className={styles.FieldInput}>
            <select
              value={requestType}
              onChange={(e) => setRequestType(e.target.value)}>
              <option value="BugSupport">Bug/Support</option>
              <option value="Development">Development</option>
            </select>
          </div>
          <div className={styles.FieldInfo}>
            Choose your request type to display the relevant fields needed for the type of ticket.
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <div className={styles.FieldLabel}>
            Ticket subject <span>*</span>
          </div>
          <div className={styles.FieldInput}>
            <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} onBlur={() => charLen('ticket_subject',subject, config)}/>
          </div>
          <div className={styles.ErrorMsg}>{ticketSubjectErr}</div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <div className={styles.FieldLabel}>
            Severity <span>*</span>
          </div>
          <div className={styles.FieldInput}>
            <select
              value={priority}
              onChange={(e) => setPriority(e.target.value)} >
              <option value="0">Maintenance</option>
              <option value="1">Development request (&plusmn; 2 weeks)</option>
              <option value="2">Low priority / Bug fixes (&plusmn; 48 hours)</option>
              <option value="3">Medium priority (&plusmn; 24 hours)</option>
              <option value="4">High priority (&plusmn; 2 hours)</option>
              <option value="5">Critical priority (less than an hour)</option>
            </select>
          </div>
          <div className={styles.FieldInfo}>
            {severityDesc[priority]}
          </div>
        </div>

        <DesktopLayout>
          <div className={styles.FieldGroupDouble}>
            <div className={styles.FieldGroupL}>
              <div className={styles.FieldLabel}>
                Product <span>*</span>
              </div>
              <div className={styles.FieldInput}>
                <select
                  value={product}
                  onChange={(e) => setProducts(e.target.value)} >
                  {productApi.map((item) => (
                    <option key={item.name} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={styles.FieldGroupR}>
              <div className={styles.FieldLabel}>
                Category <span>*</span>
              </div>
              <div className={styles.FieldInput}>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)} >
                  {categoryApi.map((item) => (
                    <option key={item.name} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </DesktopLayout>

        <MobileLayout>
          <div className={styles.FieldGroupSingle}>
            <div className={styles.FieldLabel}>
              Product <span>*</span>
            </div>
            <div className={styles.FieldInput}>
              <select
                value={product}
                onChange={(e) => setProducts(e.target.value)} >
                {productApi.map((item) => (
                  <option key={item.name} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={styles.FieldGroupSingle}>
            <div className={styles.FieldLabel}>
              Category <span>*</span>
            </div>
            <div className={styles.FieldInput}>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)} >
                {categoryApi.map((item) => (
                  <option key={item.name} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </MobileLayout>

        <div className={styles.FieldGroupSingle}>
          <div className={styles.FieldLabel}>
            Description of the issue <span>*</span>
          </div>
          <div className={styles.FieldInput}>
            <textarea value={description} onChange={(event) => setDescription(event.target.value)} onBlur={() => charLen('issue_description',description, config)}/>
          </div>
          <div className={styles.ErrorMsg}>{descriptionErr}</div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <div className={styles.FieldLabel}>
            How did you get to the error/problem (steps to reproduce)? <span>*</span>
          </div>
          <div className={styles.FieldInput}>
            <textarea value={stepsToReproduce} onChange={(event) => setStepsToReproduce(event.target.value)} onBlur={() => charLen('steps_to_reproduce',stepsToReproduce, config)}/>
          </div>
          <div className={styles.ErrorMsg}>{stepsToReproduceErr}</div>
        </div>


      {requestType === 'Development' ?
        <>
        <div className={styles.FormHeader}>
          <div className={styles.Heading}>
            <h3>Requirements</h3>
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <div className={styles.FieldLabel}>
            Stakeholder Name <span>*</span>
          </div>
          <div className={styles.FieldInput}>
          <input
            type="text"
            value={stakeHolder}
            onChange={(e) => setStakeHolder(e.target.value)} />
          </div>
          <div className={styles.FieldInfo}>
            Give us your name so that we know who to provide feedback to.
          </div>
        </div>

        <DesktopLayout>
          <div className={styles.FieldGroupDouble}>
            <div className={styles.FieldGroupL}>
              <div className={styles.FieldLabel}>
                Client priority <span>*</span>
              </div>
              <div className={styles.FieldInput}>
                <select
                    value={clientPriority}
                    onChange={(e) => setClientPriority(e.target.value)} >
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
            </div>

            <div className={styles.FieldGroupR}>
              <div className={styles.FieldLabel}>
                User role <span>*</span>
              </div>
              <div className={[styles.FieldInput, styles.MultiSelect].join(' ')}>
                <Select
                  isMulti={true}
                  onChange={(e) => setUserRole(e)}
                  options={[...Roles]}
                />
              </div>
              <div className={styles.FieldInfo}>
                Tell us for which user role the request is: Practitioner, Practice manager, Receptionist, Billing expert, Cashier, Credit controller, Stock controller, Bookkeeper, or other.
              </div>
            </div>
          </div>
        </DesktopLayout>

        <MobileLayout>
          <div className={styles.FieldGroupSingle}>
            <div className={styles.FieldLabel}>
              Client priority <span>*</span>
            </div>
            <div className={styles.FieldInput}>
              <select
                  value={clientPriority}
                  onChange={(e) => setClientPriority(e.target.value)} >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
            </div>
          </div>

          <div className={styles.FieldGroupSingle}>
            <div className={styles.FieldLabel}>
              User role <span>*</span>
            </div>
            <div className={[styles.FieldInput, styles.MultiSelect].join(' ')}>
              <Select
                isMulti={true}
                onChange={(e) => setUserRole(e)}
                options={[...Roles]}
              />
            </div>
            <div className={styles.FieldInfo}>
              Tell us for which user role the request is: Practitioner, Practice manager, Receptionist, Billing expert, Cashier, Credit controller, Stock controller, Bookkeeper, or other.
            </div>
          </div>

        </MobileLayout>

        <div className={styles.FieldGroupSingle}>
          <div className={styles.FieldLabel}>
            Reason <span>*</span>
          </div>
          <div className={styles.FieldInput}>
            <textarea onChange={(event) => setReason(event.target.value)} />
          </div>
          <div className={styles.FieldInfo}>
            We need to know why you are requesting this, so that we can understand the requirement better.
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <div className={styles.FieldLabel}>
            Requirement type <span>*</span>
          </div>
          <div className={styles.FieldInput}>
            <select
              value={requirementType}
              onChange={(e) => setRequirementType(e.target.value)} >
              <option value="Business Requirement">Business Requirement</option>
              <option value="System Requirement">System Requirement</option>
              <option value="User Requirement">User Requirement</option>
            </select>
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <div className={styles.FieldLabel}>
            Expected behaviour <span>*</span>
          </div>
          <div className={styles.FieldInput}>
            <textarea onChange={(event) => setExpectedBehaviour(event.target.value)} />
          </div>
          <div className={styles.FieldInfo}>
            Tell us what you expect the system should do.
          </div>
          <YoutubeEmbed videoId={'OWG0KE5GZJY'} />
        </div>
      </>
      :null}

    <div className={styles.FieldGroupSingle}>
      <div className={styles.FieldLabel}>
        Additional information
      </div>

      <div className={styles.FieldInput}>
        <ReactQuill theme="snow"
          modules={modules}
          formats={formats}
          value={message} onChange={setMessage}
          className={styles.Editor}
          placeholder="Type any additional information here..."
        />
      </div>
    </div>

    <div className={styles.FieldGroupSingle_File}>
      <div className={styles.FilesUpload}>
        <label className={styles.FilesBtn}>
          Upload files...
          <input
            type="file"
            multiple
            accept={acceptsMimeTypes}
            onChange={(e) => {
              const { files } = e.target;

              setFiles((currentFiles) => {
                if (files) {
                  return [...currentFiles, ...Array.from(files)];
                }
                return currentFiles;
              });
            }}
          />
        </label>
      </div>

      <div className={styles.UploadedFiles}>
        <FilesDisplay files={files} onRemove={onRemoveFile} />
      </div>

    </div>
    {!buttonState ?
      <button onClick={validateFields} disabled={stepsToReproduce==="" && description==="" && subject==="" && buttonState} className={styles.SubmitBtn}>
        Submit New Ticket
      </button>
    : null}


    {errors ? (
      <CustomPopup
        onClose={popupCloseHandler}
        show={visibility}
        title={'Validation error'}
        >
          <p>The following mandatory fields are not completed: <br/>
          {errors}</p>
      </CustomPopup>
      ):null
      }
      </div>

    ): <Redirect push to={{ pathname: "/login", state: { referrer: window.location.origin }}} />
  );
};

export default withRouter(NewIssue);

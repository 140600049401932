import './App.scss';

import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { get, post, fetchData } from './api';
import { setFavicon } from './utils/utils';
import Navbar from './components/navbar/Navbar';
import ToastsContainer, { ToastsContext } from './components/toast/Toast';
import { AuthTokenContext, UserContext, ViewContext, ConfigContext } from './context';
import RepoOverview from './pages/dashboard/RepoOverview';
import WeekOverview from './pages/dashboard/WeekOverview';
import RepoOverviewInternal from './pages/dashboardinternal/RepoOverviewInternal';
import WeekOverviewInternal from './pages/dashboardinternal/WeekOverviewInternal';
import Devops from './pages/devops/Issue';
import QualityAssurance from './pages/qa/QualityAssurance';
import TicketList from './pages/home/components/IssueList';
import Home from './pages/home/Home';
import Issue from './pages/issue/Issue';
import ReopenIssue from './pages/issue/ReopenIssue';
import Login from './pages/login/Login';
import NewIssue from './pages/new-issue/NewIssue';
import NotFound from './pages/not-found/NotFound';
import Quote from './pages/quote';
import RequestPwReset from './pages/request-pw-reset/RequestPwReset';
import StarStop from './pages/star-stop/StarStop';
import Support from './pages/support/Issue';
import Survey from './pages/survey/Survey';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import PopiaPwReset from './pages/popia-pw-reset/PopiaPwReset';
import Invoice from './pages/invoices/Invoices';
import SupportStats from './pages/support-stats/SupportStats';
import Releases from './pages/releases/Releases';
import UpdateInformation from './pages/update-information-request/Update';
import InfoUpdateRequests from './pages/update-information-request/Requests';
import ChangedInformationInRequest from './pages/update-information-request/Request';

const Pages = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route
      path="/closed-tickets"
      render={() => <TicketList ticketsStatus={'closed'} />}
    />
    <Route path="/devops" component={Devops} />
    <Route path="/QualityAssurance" component={QualityAssurance} />
    <Route path="/repo-overview2" component={RepoOverview} />
    <Route path="/repo-overview-internal2" component={RepoOverviewInternal} />
    <Route path="/week-overview2/:id" component={WeekOverview} />
    <Route
      path="/week-overview-internal2/:id"
      component={WeekOverviewInternal}
    />
    <Route path="/issue/reopen/:id/:key" component={ReopenIssue} />
    <Route path="/issue/:id" component={Issue} />
    <Route path="/log-ticket" component={NewIssue} />
    <Route path="/invoices" component={Invoice}/>
    <Route path="/login" component={Login} />
    <Route path="/otp-validate" component={Login} />
    <Route path="/password-reset" component={Login} />
    <Route path="/password-reset/success" component={Login} />
    <Route path="/popia-pw-reset" component={PopiaPwReset} />
    <Route path="/quote/:id" component={Quote} />
    <Route path="/register" component={Login} />
    <Route path="/register/success" component={Login} />
    <Route path="/releases" component={Releases} />
    <Route path="/request-reset" component={RequestPwReset} />
    <Route path="/support2" component={Support} />
    <Route path="/survey" component={Survey} />
    <Route path="/task/star/:taskId" component={StarStop} />
    <Route path="/task/stop/:taskId" component={StarStop} />
    <Route path="/terms-and-conditions" component={TermsAndConditions} />
    <Route path="/support-stats" component={SupportStats} />
    <Route path="/update-information" component={UpdateInformation} />
    <Route path="/Info-Update-Requests" component={InfoUpdateRequests} />
    <Route path="/Request/:id" component={ChangedInformationInRequest} />
    <Route component={NotFound} />
  </Switch>
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenExpires: new Date(localStorage.getItem('tokenExpires')),
      user: null,
      view: localStorage.getItem('view') || 'cards',
      toasts: [],
      config: [],
    };
  }

  setConfig = (config) => {
    this.setState({ config });

    if (config?.client_portal_favicon) {
      setFavicon(config.client_portal_favicon);
    } else {
      setFavicon(null);
    }
  };

  refreshConfig = async () => {
    await fetchData('/odooConfig', this.setConfig);
  };

  setToasts = (newToasts) => this.setState({ toasts: newToasts });

  appendToast = (newToast) =>
    this.setState((oldState) => ({ toasts: [...oldState.toasts, newToast] }));

  dismissToast = (index) => {
    console.log('dismiss', index);
    this.setState((oldState) => ({
      toasts: oldState.toasts.filter((_, i) => i !== index),
    }));
  };

  authlessPath = (location) => {
    const firstDir = location.pathname.split('/')[0];
    return [
      '',
      'login',
      'password-reset',
      'survey',
      'terms-and-conditions',
    ].includes(firstDir);
  };

  shouldGoHome = (location) => {
    return !this.isTokenValid() && !this.authlessPath(location);
  };

  componentWillUnmount() {
    this.history_unlisten();
  }

  async componentDidMount() {
    this.history_unlisten = this.props.history.listen((location) => {
      if (this.shouldGoHome(location)) {
        this.props.history.push({ pathname: '/login' });
      }
    });

    fetchData('/odooConfig', this.setConfig);

    if (this.isTokenValid()) {
      this.setState({ user: await this.fetchUser() });
    }
  }

  isTokenValid = () => this.state.tokenExpires > new Date();

  setView = (newView) => {
    localStorage.setItem('view', newView);
    this.setState({ view: newView });
  };

  invalidateAuthToken = async () => {
    console.log('invalidate auth token');

    await get('/user/logout');
    document.cookie = `authToken=; Max-Age=0`;
    localStorage.removeItem('tokenExpires');
    this.setState({ tokenExpires: new Date() });
    this.props.history.push({ pathname: '/login' });
  };

  fetchUser = async () => {
    const res = await get('/user');

    if (res.ok) {
      return await res.json();
    } else {
      await this.invalidateAuthToken();
    }
  };

  setTokenExpires = async (tokenExpires) => {
    localStorage.setItem('tokenExpires', tokenExpires);
    this.setState({
      tokenExpires: new Date(tokenExpires),
      user: await this.fetchUser(),
    });
  };

  logout = async () => await this.invalidateAuthToken();

  switchUser = async (to_user_id) => {
    const resp = await post('/user/switch_user', {
      to_user_id: parseInt(to_user_id),
    });

    if (resp.ok) {
      const json = await resp.json();
      await this.setTokenExpires(json.token_expires);
      this.props.history.go(0);
    } else {
      await this.invalidateAuthToken();
    }
  };

  render() {
    return (
      <AuthTokenContext.Provider
        value={{
          isTokenValid: this.isTokenValid,
          setTokenExpires: this.setTokenExpires,
        }}
      >
        <UserContext.Provider
          value={{
            user: this.state.user,
            setUser: this.state.setUser,
          }}
        >
          <ViewContext.Provider
            value={{
              view: this.state.view,
              setView: this.setView,
            }}
          >
            <ConfigContext.Provider
              value={{
                config: this.state.config,
                setConfig: this.setConfig,
                refreshConfig: this.refreshConfig,
              }}
            >
              <ToastsContext.Provider value={{ appendToast: this.appendToast }}>
                <div>
                  <Navbar
                    user={this.state.user}
                    onLogout={this.logout}
                    onSwitchUser={this.switchUser}
                    tokenValid={this.isTokenValid()}
                  />
                  <Pages />
                </div>
                <ToastsContainer
                  toasts={this.state.toasts}
                  dismissToast={this.dismissToast}
                />
              </ToastsContext.Provider>
            </ConfigContext.Provider>
          </ViewContext.Provider>
        </UserContext.Provider>
      </AuthTokenContext.Provider>
    );
  }
}

export default withRouter(App);

export const setFavicon = (href) => {
    const favicon = document.querySelector("link[rel='shortcut icon']");
    if (favicon) {
        favicon.href = `data:image/png;base64,${href}`;
    } else {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = `data:image/png;base64,${href}`;
        document.head.appendChild(newFavicon);
    }
}
